<template>
  <div class="am-lead-success-view">
    <div class="am-lead-success-view__inner">
      <amui-headline
        text="Vielen Dank für Ihre Anfrage!"
        html-tag="h1"
        looks-like="h5"
        class="am-lead-success-view__headline"
      />
      <div class="am-lead-success-view__subtitle">
        Wie werden uns schnellstmöglich bei Ihnen melden.
      </div>
      <amui-button
        v-if="!isAdMode"
        label="Zur Fahrzeugsuche"
        icon-right="arrow-slide-right"
        @click.prevent="onSearch"
        class="am-lead-success-view__cta"
      />
    </div>
    <am-vehicle-cross-selling-slider
      v-if="vehicles.length"
      :vehicles="vehicles"
      :search-href="searchHref"
      :search-label="$t('crossSelling.searchLabel')"
      :navigate-forward-label="$t('crossSelling.navigate.forward')"
      :navigate-backward-label="$t('crossSelling.navigate.backward')"
      @view="onViewVehicle"
      @favor="onFavoriteChange"
      @search="onSearch"
      class="am-lead-success-view__cross-selling"
    ></am-vehicle-cross-selling-slider>
  </div>
</template>
<script>
import { getId as getVehicleId } from '@/app/../../utils/vehicle'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'
import { mapGetters } from 'vuex'
import { searchVehiclesByCompany } from '@/app/requests/vehicles'
import AmVehicleCrossSellingSlider from '@/app/components/vehicle-cross-selling-slider/vehicle-cross-selling-slider.vue'
import vehicleCardHelperMixin from '@/app/mixins/vehicle-card-helper.mixin'
import { getUrlQueryByLocale } from '@/app/mixins/search-params-mapping.mixin'
import { mapActions } from 'vuex'
import vehicleTrackingMixin from '@/app/mixins/vehicle-tracking.mixin'
import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'AmLeadSuccessView',
  components: {
    AmuiHeadline,
    AmuiButton,
    AmVehicleCrossSellingSlider
  },
  mixins: [vehicleCardHelperMixin, vehicleTrackingMixin],
  props: {
    modelCode: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      brand: null,
      modelGroup: null,
      rawVehiclesData: []
    }
  },
  created() {
    this.fetchVehicles()
  },
  mounted() {
    this.gtm = getGTMTracker()
  },

  computed: {
    ...mapGetters('core', ['isAdMode', 'companyId']),
    vehicles() {
      let vehicles = []

      if (Array.isArray(this.rawVehiclesData)) {
        vehicles = this.rawVehiclesData.map(rawVehicle => {
          const vehicleData = this.getMappedVehicleCardData(rawVehicle)

          let sources = []
          let fallbackSource = null

          if (vehicleData.media.length > 0) {
            const media = vehicleData.media[0]

            sources = [
              {
                media: 'all',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 480),
                  this.getSourceByMediaAndWidth(media, 960) + ' 2x'
                ].join(', ')
              }
            ]

            fallbackSource = sources[0].srcset
          }

          return Object.assign({}, vehicleData, {
            sources,
            fallbackSource: fallbackSource
          })
        })
      }

      return vehicles
    },
    searchHref() {
      const query = getUrlQueryByLocale(this.searchParams)
      return this.$router.resolve({
        name: 'app',
        query: Object.assign({}, query, {
          sort: 'desc'
        })
      }).href
    },
    searchParams() {
      return {
        make: this.brand,
        modelGroup: this.modelGroup,
        flags: []
      }
    }
  },
  methods: {
    ...mapActions('favorites', ['toggleFavoriteStateByRawVehicleData']),
    async fetchVehicles() {
      // we will fetch thebrand and  modelGroup from the first vehicle
      // and then fetch the vehicles with the same modelGroup as cross selling cars
      try {
        const res = await searchVehiclesByCompany(
          this.companyId,
          {
            amModelCode: this.modelCode,
            flags: []
          },
          {
            limit: 1
          }
        )

        if (res?.docs?.length && res.docs[0].modelGroup) {
          this.brand = res.docs[0].make
          this.modelGroup = res.docs[0].modelGroup

          const res2 = await searchVehiclesByCompany(
            this.companyId,
            this.searchParams,
            {
              limit: 10,
              sort: '-price.amConsumerPriceGrossCent mobileAdId'
            }
          )

          if (res2?.docs) {
            this.rawVehiclesData = res2.docs
          }
        }
      } catch {
        this.rawVehiclesData = []
      }
    },
    getSourceByMediaAndWidth(media, width) {
      let src = null

      if (media.type === 'cpo') {
        const splittedSegments = media.normal.split('/')
        const filename = splittedSegments.pop()
        src = splittedSegments.join('/') + '/tr:w-' + width + '/' + filename
      } else {
        src = media.normal
      }

      return src
    },
    onFavoriteChange(vehicleId) {
      if (Array.isArray(this.rawVehiclesData)) {
        const foundRawVehicleData = this.rawVehiclesData.find(
          rawVehicleData => {
            return getVehicleId(rawVehicleData) === vehicleId
          }
        )

        if (foundRawVehicleData !== undefined) {
          this.toggleFavoriteStateByRawVehicleData(foundRawVehicleData).then(
            added => {
              if (added) {
                this.trackAddToWishlist(foundRawVehicleData)
              }
            }
          )
        }
      }
    },
    onViewVehicle(vehicleId) {
      this.trackSelectItem(
        this.rawVehiclesData.find(raw => vehicleId === getVehicleId(raw))
      )

      this.$router.push({
        name: 'detail',
        params: {
          id: vehicleId
        }
      })
    },
    trackSelectItem(item) {
      const payload = {
        ecommerce: {
          items: [this.getVehicleTrackingObject(item)]
        }
      }
      this.gtm.trackEvent({
        name: 'select_item',
        payload
      })
    },
    trackAddToWishlist(item) {
      const vehicleTrackingObject = this.getVehicleTrackingObject(item)

      const payload = {
        ecommerce: {
          currency: 'EUR',
          value: vehicleTrackingObject.price,
          items: [vehicleTrackingObject]
        },
        component: 'vdp-cross-selling'
      }

      this.gtm.trackEvent({
        name: 'add_to_wishlist',
        payload
      })
    },
    onSearch() {
      window.location = this.searchHref
    }
  }
}
</script>
